<template>
    <div
        class="livestream-player-wrap"
        :style="cssProps"
    >
        <!-- The countdown keeps track of when a stream is live or not, so it must always be present -->
        <sc-countdown
            @stream-ended="handleStreamEnd"
            @stream-started="handleStreamStart"
            @stream-updated="updateStream"
            :dark="dark"
            :currentStream="currentStream"
            :hasCountdown="hasCountdown"
            :version="version"
            :class="{
                'invisible' : isHideCountdown
            }"

        ></sc-countdown>

        <!-- When not streaming show some offline content -->
        <template v-if="!isStreaming">
            <sc-rotator
                v-if="useStreamRotator"
                :rotatorData="currentStream.offline_content.rotator"
            ></sc-rotator>

            <sc-video
                v-else-if="useStreamVideoLoop && !audioOnly"
                :autoplay="autoPlay ? 'muted' : undefined"
                :loop="true"
                key="streamLoop"
                :src="this.currentStream.offline_content.video.url"
            ></sc-video>

            <div
                v-else-if="useStreamImage"
                class="livestream-image"
            >
                <img
                    :alt="currentStream.offline_content.image.description"
                    :src="currentStream.offline_content.image.url"
                    onerror="this.style.visibility = 'hidden'"
                />
            </div>

             <sc-rotator
                v-else-if="useSettingsRotator"
                :rotatorData="streamSettings.default_offline_content.rotator"
            ></sc-rotator>

            <sc-video
                v-else-if="useSettingsVideoLoop && !audioOnly"
                :autoplay="autoPlay ? 'muted' : undefined"
                key="settingsLoop"
                :loop="true"
                :src="streamSettings.default_offline_content.video.url"
            ></sc-video>

            <div
                v-else-if="streamSettings.social_image_url"
                class="livestream-image"
            >
                <img :src="streamSettings.social_image_url" />
            </div>

            <div
                v-else-if="streamSettings.logo_url"
                class="livestream-image"
            >
                <img :src="streamSettings.logo_url" />
            </div>

            <!-- Banners -->
            <div class="livestream-preparing" v-if="isStreamPreparing && !isStreamReady">
                <div class="livestream-preparing-content">
                    <div class="livestream-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    <div class="livestream-preparing-text">
                        <h3 class="mb-2">Preparing Stream...</h3>
                        <p class="mb-0">Please stand by as we get your stream ready to go live.</p>
                    </div>
                </div>
            </div>


            <div class="livestream-ready" v-if="isStreamReady">
                <div class="livestream-ready-content">
                    <div class="livestream-ready-play-icon vjs-icon-play" @click="goLiveClicked()"></div>
                    <div class="livestream-ready-text">
                        <h3 class="mb-2">Your stream is ready!</h3>
                        <p class="mb-0">Click play to go live.</p>
                    </div>
                </div>
            </div>
        </template>



        <!-- When we are streaming, and there is a stream file, show it -->
        <template v-else-if="currentStream && currentStream.m3u8">
            <component
                :is="selectedType == 'audio' ? 'sc-audio' : 'sc-video'"
                :autoplay="autoPlay ? 'muted' : undefined"
                :src="currentStream.m3u8"
                :live="true"
                key="stream"
                :poster="useStreamImage ? currentStream.offline_content.image.url : ''"
                :streamingid="currentStream.id"
                :streamingtitle="currentStream.title"
                :siteid="currentStream.siteid"
            ></component>
        </template>

        <!-- Only display information for the upcoming stream if it exists, not v2 layout: -->
        <giving-bottom-banner
            :streams="streams"
            :streamSettings="streamSettings"
            :isStreaming="isStreaming"
        >
        </giving-bottom-banner>
        <!-- Only display information for the upcoming stream if it exists -->
        <sc-playlist-item
            v-if="hasDescription && playlistData.title && version < 2"
            @media-selected="selectedType = $event"
            :dark="dark"
            :full="true"
            :playable="false"
            :hasEventSettings="hasEventSettings"
            :selectedType="selectedType"
            :sermon="playlistData"
            :startExpanded="false"
        ></sc-playlist-item>
        <!-- Load this hidden so the outer has all the sermon cloud postMessage info. -->
        <sc-more-info-share v-if="version >= 2" v-show="false" :sermon="playlistData"></sc-more-info-share>
    </div>
</template>

<script>
import { EventBus } from '../../mixins/EventBus';
import givingBottomBanner from './givingBottomBanner.vue'

export default {
    components: {
        givingBottomBanner,
    },
    props: {
        streams: Object,
        streamSettings: Object,
        version: String
    },
    data() {
        return {
            audioOnly: false,
            autoPlay: true,
            currentStream: undefined,
            dark: true,
            hasCountdown: true,
            hasDescription: true,
            hasEventSettings: false,
            isStreaming: false,
            player: undefined,
            selectedType: 'video',
            isStreamPreparing : false,
            isStreamReady : false,
            isHideCountdown : false
        }
    },
    created() {
        // Parse url search query looking for embed specific styling
        var queryObject = this.getSearchQueryAsObject();

        // Only play the stream audio when the audioOnly GET param is set to true
        this.audioOnly = queryObject.audioOnly === 'true';

        // Videos auto play by default and can be disabled with a query
        this.autoPlay = !queryObject.autoPlay || queryObject.autoPlay === 'true';

        // Use an audio player if audioOnly is true
        this.selectedType = this.audioOnly ? 'audio' : 'video';

        // The countdown is shown by default and can be hidden with a query
        this.hasCountdown = !queryObject.countdown || queryObject.countdown === 'true';

        // The description is shown by default and can be hidden with a query
        this.hasDescription = !queryObject.description || queryObject.description === 'true';

        // The admin dashboard gets an option to show event settings
        this.hasEventSettings = queryObject.eventSettings && queryObject.eventSettings === 'true';

        // The countdown uses the dark theme by default and can use the light theme with a query
        this.dark = queryObject.theme === 'light' ? false : true;

        // Set the current stream to the 1st stream listed
        this.currentStream = this.streams ? this.streams.data[0] : null;

        this.isStreamPreparing = false;
        this.isStreamReady = false;

        // Get the current player from child components
        EventBus.$on('player', (e) => {
            this.player = e;
        });

        // Listen for streaming dashboard hide / show toggles
        this.handleDashboardToggleEvents();
    },
    computed: {
        /**
         * Expose user set css variables for use throughout the embed
         */
        cssProps() {
            return {
                '--user-alternate-color': this.streamSettings.alternate_color,
                '--user-header-color': this.streamSettings.header_color,
                '--user-link-color': this.streamSettings.link_color
            };
        },

        /**
         * Reformat the current stream data to match the sermon model so we can use the same playlist item
         */
        playlistData() {
            if (!this.currentStream) {
                return {};
            }

            var playlistData = {
                media: {},
                summary: this.currentStream.description,
                passages: this.currentStream.passages,
                title: this.currentStream.title,
                preacher: this.currentStream.preacher,
                date: this.currentStream.start,
                categories: this.currentStream.categories, // appears to be missing
                series: this.currentStream.series, // appears to be missing
            };

            // If this is not audio only, add audio & video media items which will show the audio & video selectors
            if (!this.audioOnly) {
                 playlistData.media = {
                    audio: this.currentStream.m3u8,
                    video: this.currentStream.m3u8,
                };
            }

            return playlistData;
        },

        /**
         * Check that the stream rotator has content
         *
         * @returns {number}
         */
        useStreamRotator() {
            return this.currentStream &&
                   this.currentStream.offline_content.rotator &&
                   Object.keys(this.currentStream.offline_content.rotator).length;
        },

        /**
         * Check the the stream specific video loop has content
         *
         * @returns {number}
         */
        useStreamVideoLoop() {
            return this.currentStream &&
                   this.currentStream.offline_content.video &&
                   Object.keys(this.currentStream.offline_content.video).length;
        },

        /**
         * Check the the stream specific image has content
         *
         * @returns {number}
         */
        useStreamImage() {
            return this.currentStream &&
                   this.currentStream.offline_content.image &&
                   Object.keys(this.currentStream.offline_content.image).length;
        },

        /**
         * Check the default settings rotator has content
         *
         * @returns {number}
         */
        useSettingsRotator() {
            return this.streamSettings.default_offline_content.rotator &&
                   Object.keys(this.streamSettings.default_offline_content.rotator).length;
        },

        /**
         * Check the default settings video loop has content
         *
         * @returns {number}
         */
        useSettingsVideoLoop() {
            return this.streamSettings.default_offline_content.video &&
                   Object.keys(this.streamSettings.default_offline_content.video).length;
        }
    },
    methods: {
        /**
         * Convert the search query to an object so we can know about user set countdown display & dark mode
         *
         * @returns {Object}
         */
        getSearchQueryAsObject() {
            var queryString = window.location.search.substr(1);
            var obj = {};
            var pairs = queryString.split('&');
            for (var i = 0; i < pairs.length; i++) {
                var pair = pairs[i].split('=');
                obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
            }
            return obj;
        },

        /**
         * The countdown tells us when a stream starts, then we hide offline content and show the stream video
         */
        handleStreamStart() {
            this.isStreaming = true;

            window.parent.postMessage(['streamLive'], '*');

            if (typeof window.sermonCloudResizeHeight == 'function') {
                window.sermonCloudResizeHeight();
            }
        },

        /**
         * The countdown tells us when a stream ends, then we hide the old stream, switch to the next stream,
         * and show offline content relevant to the upcoming stream
         *
         * @param {Object} nextStream - the next stream to display
         */
        handleStreamEnd(nextStream) {
            this.isStreaming = false;
            this.currentStream = nextStream;

            window.parent.postMessage(['streamOffline', nextStream], '*');

            if (typeof window.sermonCloudResizeHeight == 'function') {
                window.sermonCloudResizeHeight();
            }
        },

        /**
         * Update the current stream with any changed information from the server
         *
         * @param {Object} stream - a stream with up to date data
         */
        updateStream(stream) {
            this.currentStream = stream;

            window.parent.postMessage(['streamUpdated', stream], '*');

            if (typeof window.sermonCloudResizeHeight == 'function') {
                window.sermonCloudResizeHeight();
            }
        },

        /**
         * Update the video player when the streaming dashboard hides / shows the video
         */
        handleDashboardToggleEvents() {
            let self = this;

            // Streams all start out muted
            let isMutedByUser = true;

            // Streams all start out unpaused
            let isPausedByUser = false;

            window.addEventListener(
                'message',
                (e) => {
                    var eventName = e.data[0];
                    switch (eventName) {
                        case 'hideStream':
                            // Remember if the video was muted or not before hiding
                            if (self.player.muted()) {
                                isMutedByUser = true;
                            } else {
                                isMutedByUser = false;

                                // Mute the video when we hide the stream so sound isn't coming from nowhere
                                self.player.muted(true);
                            }

                            // Remember if the video was paused or not before hiding
                            if (self.player.paused()) {
                                isPausedByUser = true;
                            } else {
                                isPausedByUser = false;
                            }

                            break;
                        case 'showStream':
                            // If the video was unmuted before we hid the dashboard, unmute it when showing
                            if (!isMutedByUser) {
                                self.player.muted(false);
                            }

                            // If the video was playing before we hid the dashboard, start playing it when showing
                            // This is needed for an edge case, where the 1st time a video is unhidden it gets paused
                            if (!isPausedByUser) {
                                self.player.play();
                            }

                            break;
                        case 'showPreparingStream':
                            self.isStreamPreparing = true;
                            window.parent.postMessage(['showPreparingStreamTriggered'], '*');
                            break;
                        case 'hidePreparingStream':
                            self.isStreamPreparing = false;
                            break;
                        case 'showReadyStream':
                            self.isStreamReady = true;
                            self.isStreamPreparing = false;
                            self.isHideCountdown = true;
                            break;
                        case 'hideReadyStream':
                            self.isStreamReady = false;
                            break;
                        case 'hideCountdown':
                            self.isHideCountdown = true;
                            break;
                        case 'showCountdown':
                            self.isHideCountdown = false;
                            break;
                    }
                },
                false
            );
        },

        goLiveClicked() {
            window.parent.postMessage(['goLive'], '*');
        }
    }
}
</script>

<style lang="scss" scoped>
    .invisible {
        display: none;
    }
    .livestream-player-wrap {
        background-color: rgba(35, 35, 35, 1);
        color: rgba(255, 255, 255, 1);
        border-bottom: 1px solid rgba(155, 155, 155, 0.16);
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.05);
        position: relative;

        .livestream-preparing {
            z-index: 5000;
            position: absolute;
            width: 100%;
            top: 50%;
            background: rgb(1 1 1 / 60%);
            translate: translateY(-50%);
            transform: translateY(-50%);
            display: flex;
            flex-direction: column;
            padding: 30px 0;
            .livestream-preparing-content {
                display: flex;
                gap: 50px;
                justify-content: center;
                .livestream-spinner,
                .livestream-spinner div,
                .livestream-spinner div:after {
                  box-sizing: border-box;
                }
                .livestream-spinner {
                  color: currentColor;
                  display: inline-block;
                  position: relative;
                  width: 48px;
                  height: 48px;
                }
                .livestream-spinner div {
                  transform-origin: 40px 30px;
                  animation: livestream-spinner 1.2s linear infinite;
                }
                .livestream-spinner div:after {
                  content: " ";
                  display: block;
                  position: absolute;
                  top: 3.2px;
                  left: 36.8px;
                  width: 5.5px;
                  height: 14px;
                  border-radius: 4px;
                  background: currentColor;
                }
                .livestream-spinner div:nth-child(1) {
                  transform: rotate(0deg);
                  animation-delay: -0.7s;
                }
                .livestream-spinner div:nth-child(2) {
                  transform: rotate(45deg);
                  animation-delay: -0.6s;
                }
                .livestream-spinner div:nth-child(3) {
                  transform: rotate(90deg);
                  animation-delay: -0.5s;
                }
                .livestream-spinner div:nth-child(4) {
                  transform: rotate(135deg);
                  animation-delay: -0.4s;
                }
                .livestream-spinner div:nth-child(5) {
                  transform: rotate(180deg);
                  animation-delay: -0.3s;
                }
                .livestream-spinner div:nth-child(6) {
                  transform: rotate(225deg);
                  animation-delay: -0.2s;
                }
                .livestream-spinner div:nth-child(7) {
                  transform: rotate(270deg);
                  animation-delay: -0.1s;
                }
                .livestream-spinner div:nth-child(8) {
                  transform: rotate(315deg);
                  animation-delay: 0s;
                }
                @keyframes livestream-spinner {
                  0% {
                    opacity: 1;
                  }
                  100% {
                    opacity: 0;
                  }
                }
                .livestream-preparing-text {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    h3 {
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 28px;
                    }
                    p {
                        line-height: 24px;
                    }
                }
            }
        }

        .livestream-ready {
            position: absolute;
            width: 100%;
            top: 50%;
            background: rgb(24 99 204 / 85%);
            translate: translateY(-50%);
            transform: translateY(-50%);
            display: flex;
            flex-direction: column;
            padding: 30px 0;
            .livestream-ready-content {
                display: flex;
                gap: 30px;
                justify-content: center;
                align-items: center;
                .livestream-ready-play-icon {
                    font-size: 35px;
                    border: 5px solid white;
                    border-radius: 50%;
                    width: 48px;
                    display: flex;
                    justify-content: center;
                    height: 48px;
                    vertical-align: middle;
                    line-height: 38px;
                    cursor: pointer;
                }
                .livestream-ready-text {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    h3 {
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 28px;
                    }
                    p {
                        line-height: 24px;
                    }
                }
            }
        }

        .sermon-player {
            width: 100%;
        }
    }

    // Style offline content images when there are not rotators or video loops to display
    // Ensure they are full width and the same height as the video which will replace them
    .livestream-image {
        height: 0;
        overflow: hidden;
        padding-bottom: 56.25%;

        img {
            height: auto;
            width: 100%;
        }
    }
</style>
