<template>
    <div class="livestream">
        <live-stream-and-bible-cloud-layout
            :church="church"
            :containerClass="containerClass"
            :createPrayerUrl="createPrayerUrl"
            :settings="streamSettingsLocal"
            :sidebarClass="sidebarClass"
            :detailsEnabled="streamSettings.details.enabled"
            :site="site"
            :currentStream="currentStream"
        >
            <!-- Add stream content to the main section of the page -->
            <template v-slot:content>
                <!-- When not streaming show some offline content -->
                <template v-if="!isStreaming">
                    <sc-rotator
                        v-if="useStreamRotator"
                        :rotatorData="currentStream.offline_content.rotator"
                    ></sc-rotator>

                    <sc-video
                        v-else-if="useStreamVideoLoop"
                        autoplay="muted"
                        key="streamLoop"
                        :loop="true"
                        :src="currentStream.offline_content.video.url"
                    ></sc-video>

                    <div
                        v-else-if="useStreamImage"
                        class="livestream-image"
                    >
                        <img
                            :alt="currentStream.offline_content.image.description"
                            :src="currentStream.offline_content.image.url"
                            onerror="this.style.visibility = 'hidden'"
                        />

                    </div>

                    <sc-rotator
                        v-else-if="useSettingsRotator"
                        :rotatorData="streamSettings.default_offline_content.rotator"
                    ></sc-rotator>

                    <sc-video
                        v-else-if="useSettingsVideoLoop"
                        autoplay="muted"
                        key="settingsLoop"
                        :loop="true"
                        :src="streamSettings.default_offline_content.video.url"
                    ></sc-video>

                    <div
                        v-else-if="streamSettings.social_image_url"
                        class="livestream-image"
                    >
                        <img :src="streamSettings.social_image_url"/>
                    </div>

                    <div
                        v-else-if="streamSettings.logo_url"
                        class="livestream-image"
                    >
                        <img :src="streamSettings.logo_url"/>
                    </div>
                    <div class="giving-bottom-banner"
                         v-if="streamSettingsLocal.give_banner.banner_giving_show_banner_in_player && isGivingBannerShow">
                        <div class="accordion" role="tablist">
                            <div class="accordion-item mb-1">
                                <div class="accordion-header text-center" @click="toggleAccordion" role="tab" :style="{
                                            'background-color' : streamSettingsLocal.give_banner.colors.background
                                        }">
                                    <button class="expande-collapse-btn" type="button" :style="{
                                            'background-color' : streamSettingsLocal.give_banner.colors.background
                                        }">
                                    <svg width="30" height="30" v-if="!isGivingBottomBarOpen" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.7">
                                        <path d="M18.9375 18L15 14.0625L11.0625 18L10.1875 17.0625L15 12.25L19.8125 17.0625L18.9375 18Z" :fill="streamSettingsLocal.give_banner.colors.text"/>
                                        </g>
                                    </svg>
                                    <svg width="30" height="30" v-else viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.7">
                                        <path d="M15 18L10.1875 13.1875L11.0625 12.25L15 16.1875L18.9375 12.25L19.8125 13.1875L15 18Z" :fill="streamSettingsLocal.give_banner.colors.text"/>
                                        </g>
                                    </svg>
                                    </button>
                                </div>
                                <div v-if="isGivingBottomBarOpen" class="accordion-body giving-bottom-banner-bg"
                                     :style="{
                                            'background-color' : streamSettingsLocal.give_banner.colors.background
                                        }">
                                    <div class="row p-2 pb-3">
                                        <div class="col-8 text-right">
                                            <div class="giving-banner-text">
                                        <span class="giving-bottom-bar-text" :style="{
                                            color : streamSettingsLocal.give_banner.colors.text
                                        }">{{ streamSettingsLocal.give_banner.description }}</span>
                                                <br/>
                                                <button @click="openGiveModel" class="mt-3 give-now-btn btn " :style="{
                                            'background-color' : streamSettingsLocal.give_banner.colors.button,
                                            'color' : streamSettingsLocal.give_banner.colors.text
                                        }">{{ streamSettingsLocal.give_banner.button_text }}
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <img v-if="streamSettingsLocal.give_banner.banner_giving_show_qr_in_banner"
                                                 :src="streamSettingsLocal.give_banner.banner_giving_qr_code_url"
                                                 class="float-left" height="84" width="84"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="showPopupModel" class="modal-overlay">
                        <div id="custom-give-form-modal" ref="customGiveFormModal" :v-model="showPopupModel"
                             role="dialog" tabindex="-1" class="modal fade show d-block" aria-modal="true">
                            <div class="modal-dialog modal-md">
                                <div role="document" id="custom-give-form-modal___BV_modal_content_"
                                     aria-labelledby="custom-give-form-modal___BV_modal_header_"
                                     aria-describedby="custom-give-form-modal___BV_modal_body_" class="modal-content">
                                    <div>
                                        <span class="close" @click="hideGivePopupModal">×</span>
                                        <iframe v-if="streamSettingsLocal.give_banner.banner_giving_form_url"
                                                :src="streamSettingsLocal.give_banner.banner_giving_form_url" frameborder="0"
                                                sandbox="allow-forms allow-same-origin allow-scripts"></iframe>
                                        <div v-else class="give-form-not-found">
                                            <p>Give Form is not available at the moment.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div tabindex="0"></div>
                        <div id="custom-give-form-modal___BV_modal_backdrop_" class="modal-backdrop fade show"></div>
                    </div>
                </template>

                <!-- When we are streaming, and there is a stream file, show it -->
                <template v-else-if="currentStream && currentStream.m3u8">
                    <sc-video
                        v-if="isStreaming"
                        autoplay="muted"
                        :src="currentStream.m3u8"
                        :loop="shouldLoop"
                        :live="true"
                        key="stream"
                        :poster="useStreamImage ? currentStream.offline_content.image.url : ''"
                        :streamingid="currentStream.id"
                        :streamingtitle="currentStream.title"
                        :siteid="currentStream.siteid"
                    ></sc-video>
                    <div class="giving-bottom-banner"
                         v-if="streamSettingsLocal.give_banner.banner_giving_show_banner_in_player && isGivingBannerShow && isBannerVisible">
                        <div class="accordion" role="tablist">
                            <div class="accordion-item mb-1">
                                <div class="accordion-header text-center" @click="toggleAccordion" role="tab" :style="{
                                            'background-color' : streamSettingsLocal.give_banner.colors.background
                                        }">
                                    <button class="expande-collapse-btn" type="button" :style="{
                                            'background-color' : streamSettingsLocal.give_banner.colors.background
                                        }">
                                    <svg width="30" height="30" v-if="!isGivingBottomBarOpen" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.7">
                                        <path d="M18.9375 18L15 14.0625L11.0625 18L10.1875 17.0625L15 12.25L19.8125 17.0625L18.9375 18Z" :fill="streamSettingsLocal.give_banner.colors.text"/>
                                        </g>
                                    </svg>
                                    <svg width="30" height="30" v-else viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.7">
                                        <path d="M15 18L10.1875 13.1875L11.0625 12.25L15 16.1875L18.9375 12.25L19.8125 13.1875L15 18Z" :fill="streamSettingsLocal.give_banner.colors.text"/>
                                        </g>
                                    </svg>
                                    </button>
                                </div>
                                <div v-if="isGivingBottomBarOpen" class="accordion-body giving-bottom-banner-bg"
                                     :style="{
                                            'background-color' : streamSettingsLocal.give_banner.colors.background
                                        }">
                                    <div class="row p-2 pb-3">
                                        <div class="col-8 text-right">
                                            <div class="giving-banner-text">
                                        <span class="giving-bottom-bar-text" :style="{
                                            color : streamSettingsLocal.give_banner.colors.text
                                        }">{{ streamSettingsLocal.give_banner.description }}</span>
                                                <br/>
                                                <button @click="openGiveModel" class="mt-3 give-now-btn btn " :style="{
                                            'background-color' : streamSettingsLocal.give_banner.colors.button,
                                            'color' : streamSettingsLocal.give_banner.colors.text
                                        }">{{ streamSettingsLocal.give_banner.button_text }}
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <img v-if="streamSettingsLocal.give_banner.banner_giving_show_qr_in_banner"
                                                 :src="streamSettingsLocal.give_banner.banner_giving_qr_code_url"
                                                 class="float-left" height="84" width="84"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="showPopupModel" class="modal-overlay">
                        <div id="custom-give-form-modal" ref="customGiveFormModal" :v-model="showPopupModel"
                             role="dialog" tabindex="-1" class="modal fade show d-block" aria-modal="true">
                            <div class="modal-dialog modal-md">
                                <div role="document" id="custom-give-form-modal___BV_modal_content_"
                                     aria-labelledby="custom-give-form-modal___BV_modal_header_"
                                     aria-describedby="custom-give-form-modal___BV_modal_body_" class="modal-content">
                                    <div>
                                        <span class="close" @click="hideGivePopupModal">×</span>
                                        <iframe v-if="streamSettingsLocal.give_banner.banner_giving_form_url"
                                                :src="streamSettingsLocal.give_banner.banner_giving_form_url" frameborder="0"
                                                sandbox="allow-forms allow-same-origin allow-scripts"></iframe>
                                        <div v-else class="give-form-not-found">
                                            <p>Give Form is not available at the moment.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div tabindex="0"></div>
                        <div id="custom-give-form-modal___BV_modal_backdrop_" class="modal-backdrop fade show"></div>
                    </div>
                </template>

                <!-- Details displayed on larger browsers -->
                <div
                    v-if="currentStream"
                    class="livestream-details"
                >
                    <div class="row">
                        <div class="col">
                            <h3 v-html="currentStream.title"></h3>
                        </div>
                        <div class="col">
                            <div class="livestream-details-date">
                                {{ currentStream.start.date }}
                            </div>
                        </div>
                    </div>

                    <br />

                    <div
                        v-if="currentStream.preacher"
                        class="font-weight-bold"
                        v-html="currentStream.preacher"
                    ></div>
                </div>
            </template>

            <!-- Add a countdown above the sidebar -->
            <template v-slot:sidebar-header>
                <sc-countdown
                    @stream-ended="handleStreamEnd"
                    @stream-started="handleStreamStart"
                    @stream-updated="updateStream"
                    :dark="dark"
                    :currentStream="currentStream"
                ></sc-countdown>
            </template>

            <!-- Add stream specific information to the details tab -->
            <template
                v-slot:details
                v-if="currentStream && currentStream.title"
            >
                <h3 v-html="currentStream.title"></h3>

                <div v-if="currentStream.stream_times">
                    {{ currentStream.stream_times }}
                </div>

                <div
                    v-if="sermonPassages"
                    v-html="sermonPassages"
                ></div>

                <div v-if="currentStream.description">
                    <br />
                    <span v-html="currentStream.description"></span>
                </div>
            </template>

        </live-stream-and-bible-cloud-layout>
    </div>
</template>

<script>
import {BCollapse, BCard, BButton, BCardHeader, BCardBody, BCardText} from 'bootstrap-vue'


export default {
    props: {
        church: Object,
        createPrayerUrl: String,
        site: Object,
        streams: Object,
        streamSettings: Object
    },
    data() {
        return {
            currentStream: undefined,
            dark: true,
            isStreaming: false,
            isGivingBannerShow: false,
            isGivingBottomBarOpen: false,
            showPopupModel: false,
            isBannerVisible: false,
            streamSettingsLocal: {},
            pollingIntervalId: null,
            pollingIntervalTime: 5000, // 5 seconds initial.
        }
    },
    mounted() {
        this.streamSettingsLocal = this.streamSettings;
        this.checkBannerVisibility();
        this.setupTimer();
        this.startPolling();
    },
    created() {
        // Set the current stream to the 1st stream listed
        this.currentStream = this.streams ? this.streams.data[0] : null;
        // Now we can safely set isGivingBannerShow with initialized isStreaming
        this.isGiveBannerVisible();
    },
    computed: {
        /**
         * Check that the stream rotator has content
         *
         * @returns {number}
         */
        useStreamRotator() {
            return this.currentStream &&
                this.currentStream.offline_content.rotator &&
                Object.keys(this.currentStream.offline_content.rotator).length;
        },

        /**
         * Check the the stream specific video loop has content
         *
         * @returns {number}
         */
        useStreamVideoLoop() {
            return this.currentStream &&
                this.currentStream.offline_content.video &&
                Object.keys(this.currentStream.offline_content.video).length;
        },

        /**
         * Check the the stream specific image has content
         *
         * @returns {number}
         */
        useStreamImage() {
            return this.currentStream &&
                this.currentStream.offline_content.image &&
                Object.keys(this.currentStream.offline_content.image).length;
        },

        /**
         * Check the default settings rotator has content
         *
         * @returns {number}
         */
        useSettingsRotator() {
            return this.streamSettings.default_offline_content.rotator &&
                Object.keys(this.streamSettings.default_offline_content.rotator).length;
        },

        /**
         * Check the default settings video loop has content
         *
         * @returns {number}
         */
        useSettingsVideoLoop() {
            return this.streamSettings.default_offline_content.video &&
                Object.keys(this.streamSettings.default_offline_content.video).length;
        },

        /**
         * Convert array of passages into a comma separated string
         *
         * @returns {String}
         */
        sermonPassages: function () {
            return this.currentStream.passages.join(', ');
        },


        /**
         * Set class names that control the size of the content, used to make room for the countdown
         */
        containerClass() {
            var containerClass = '';
            if (this.currentStream && !this.isStreaming) {
                containerClass = 'livestream-container-hasCurrentStream';
            }
            return containerClass;
        },

        /**
         * Set class names that control the size of the sidebar, used to make room for the countdown
         */
        sidebarClass() {
            var sidebarClass = '';
            if (this.currentStream && !this.isStreaming) {
                sidebarClass = 'livestream-sidebar-hasCurrentStream';
            }
            return sidebarClass;
        },

        shouldLoop() {
            return this.currentStream.m3u8.includes(".mp4");
        }
    },
    methods: {
        checkBannerVisibility() {
            // Ensure timeframes exist and currentStream is valid
            if (!this.currentStream || !this.isStreaming || !this.streamSettings.give_banner.timeframes) return;

            try {
                const timeframes = JSON.parse(this.streamSettings.give_banner.timeframes);
                // Sort timeframes by showAfter in ascending order
                timeframes.sort((a, b) => parseFloat(a.showAfter) - parseFloat(b.showAfter));

                const streamStart = new Date(this.currentStream.start.carbon).getTime();
                const streamEnd = new Date(this.currentStream.end.carbon).getTime();
                const totalStreamMinutes = (streamEnd - streamStart) / 60000;
                const currentTime = Date.now();
                const elapsedMinutes = (currentTime - streamStart) / 60000;

                // Check if current time is within any valid timeframe
                this.isBannerVisible = timeframes.some(({ showAfter, showFor }) => {
                    const showAfterMinutes = parseFloat(showAfter);
                    const showForMinutes = parseFloat(showFor);

                    // Ignore timeframes that exceed the total stream length
                    if (showAfterMinutes + showForMinutes > totalStreamMinutes) {
                        return false;
                    }

                    return elapsedMinutes >= showAfterMinutes && elapsedMinutes <= showAfterMinutes + showForMinutes;
                });
            } catch (error) {
                console.error("Error parsing timeframes:", error);
                this.isBannerVisible = false;
            }
        },
        setupTimer() {
            // Recheck banner visibility every minute
            this.intervalId = setInterval(() => {
                this.checkBannerVisibility();
            }, 60000);
        },
        toggleAccordion() {
            this.isGivingBottomBarOpen = !this.isGivingBottomBarOpen;
        },
        /**
         * The countdown tells us when a stream starts, then we hide offline content and show the stream video
         */
        handleStreamStart() {
            this.isStreaming = true;
            this.isGiveBannerVisible();
        },

        getImageUrl: function (image) {
            return process.env.ASSET_PATH + 'images/' + image
        },
        /**
         * The countdown tells us when a stream ends, then we hide the old stream
         *
         * @param {Object} nextStream - the next stream to display
         */
        handleStreamEnd() {
            this.isStreaming = false;
            this.isGiveBannerVisible();
            this.updateStream(null);
        },

        /**
         * Update the current stream with any changed information from the server
         *
         * @param {Object} stream - a stream with up to date data
         */
        updateStream(stream) {
            this.currentStream = stream;
        },

        openGiveModel() {
            this.showPopupModel = true;
        },

        hideGivePopupModal() {
            this.showPopupModel = false;
        },

        /**
         * The function use to control the give banner visibility
         */
        isGiveBannerVisible() {
            const streamSettings = this.streamSettings;

            if (!streamSettings || !streamSettings.give_banner || !streamSettings.give_banner.banner_visibility) {
                // If streamSettings, give_banner, or banner_visibility is not defined, hide the banner
                this.isGivingBannerShow = false;
                this.isGivingBottomBarOpen = false;
                this.isBannerVisible = false;
                return;
            }

            const bannerVisibility = streamSettings.give_banner.banner_visibility;
            const hasType = bannerVisibility.hasOwnProperty('type');
            const hasExpandedMode = bannerVisibility.hasOwnProperty('expanded_mode');

            if (hasType && hasExpandedMode) {
                const isAlwaysShow = bannerVisibility.type === 'always_show';
                const isControlWhileLive = bannerVisibility.type === 'control_while_live';
                const isSetTime = bannerVisibility.type === 'set_time';
                const isExpandedMode = bannerVisibility.expanded_mode === true;

                if ((isAlwaysShow && isExpandedMode) || (isControlWhileLive && this.isStreaming && isExpandedMode)) {
                    this.isGivingBannerShow = true;
                    this.isGivingBottomBarOpen = true;
                    this.isBannerVisible = true;
                } else if (isControlWhileLive && this.isStreaming && !isExpandedMode) {
                    this.isGivingBannerShow = true;
                    this.isGivingBottomBarOpen = false;
                    this.isBannerVisible = true;
                } else if (isSetTime && this.isStreaming) {
                    this.isGivingBannerShow = true;
                    this.isGivingBottomBarOpen = true;
                } else {
                    this.isGivingBannerShow = false;
                    this.isGivingBottomBarOpen = false;
                    this.isBannerVisible = false;
                }
            } else {
                // If type or expanded_mode is not defined, hide the banner
                this.isGivingBannerShow = false;
                this.isGivingBottomBarOpen = false;
                this.isBannerVisible = false;
            }
        },

        /**
         *
         * This function will be used to polling data from streamignSetting API every 5 second.
         * polling interval time can be changed.
         */
         async getGivingBannerData() {
            try {

                const response = await fetch("/streaming-settings");
                if (response.status == 200) {
                    const finalRes = await response.json();
                    this.streamSettingsLocal = { ...finalRes.data };
                } else {
                    // Error
                }
            } catch (error) {
                // Error
            }
        },

        /**
         * This function will start polling and setup time interval instance.
         */
         startPolling() {
            this.getGivingBannerData();
            this.pollingIntervalId = setInterval(this.getGivingBannerData, this.pollingIntervalTime);
        },

        /**
         * This function will stop the polling and clear setInterval.
         */
        stopPolling() {
            if (this.pollingIntervalId) {
                clearInterval(this.pollingIntervalId);
                this.pollingIntervalId = null;
            }
            this.stopPolling();
        }
    },
    beforeDestroy() {
        // Clear the timer to prevent memory leaks
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

$navbar-height: 76px;

// Style offline content images when there are not rotators or video loops to display
// Ensure they are full width and the same height as the video which will replace them
.livestream-image {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;

    img {
        height: auto;
        width: 100%;
    }
}

// Style the countdown for the livestream layout
::v-deep .sermon-countdown.sermon-countdown-dark {
    background: var(--user-alternate-color);
    border-top: none;
    padding-top: 2px;
}

::v-deep {
    // Detail text displaying under the stream
    .livestream-details {
        padding: 15px 24px;

        .livestream-details-date {
            color: rgba(0, 0, 0, 0.4);
            text-align: right;
        }

        hr {
            border: none;
            border-top: 2px solid var(--user-alternate-color);
            display: inline-block;
            margin: 0 0 10px 0;
            width: 85px;
        }
    }

    // Giving Form Bottom bar CSS
    .giving-bottom-banner-bg {
        background-color: #0F3E80;
    }

    .giving-bottom-banner {
        background-color: #0F3E80;
        min-width: 100%;
    }

    .expande-collapse-btn {
        background-color: #0F3E80;
        border-radius: 0%;
        border: 0px;
        box-shadow: none;
    }

    .give-now-btn {
        background-color: #FF600A;
    }

    .giving-bottom-bar-text {
        color: #FFFFFF;

        /* Heading 4 */
        font-size: 20px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: 28px !important; /* 140% */
    }

    .giving-banner-text {
        width: 50%;
        text-align: center;
        float: right;
    }

    #accordion-1 > .card-body > .giving-bottom-bar-text {
        color: #FFFFFF;

        /* Heading 4 */
        font-size: 1.25em !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: 28px !important; /* 140% */
    }
}

// For Giving Banner  Give Now Popup.
::v-deep {
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
    }

    .close {
        font-size: 1.5rem;
        font-weight: bold;
        line-height: 1;
        color: #000;
        opacity: 0.5;
        cursor: pointer;
        position: relative;
        top: 68px;
        right: 15px;
        display: flex;
        width: 22px;
    }

    .close:hover {
        color: #000;
        text-decoration: none;
        cursor: pointer;
        opacity: 0.75;
    }

    .give-form-not-found {
        height: 80vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #custom-give-form-modal iframe {
        height: 80vh;
        width: 100%;
    }
}

// For screens sized "lg" and wider
@include media-breakpoint-up(lg) {
    $countdown-height: 85px;

    // NB: Override videojs default sizing due to a bug in Chrome
    //     The padding-top method follows video-js' vjs-fluid pattern
    //     I have filed a bug with video-js and we should update this when they respond
    //     https://github.com/videojs/video.js/issues/7018
    ::v-deep {
        #rotator ul {
            height: 42.18vw !important;
        }

        .video-js.vjs-fluid {
            height: 42.18vw;
            padding: 0;
        }
    }

    // Make room for the countdown above the sidebar toggles
    ::v-deep .livestream-sidebar-hasCurrentStream {
        padding-top: $countdown-height;
    }

    // Make the countdown fit in the sidebar
    ::v-deep .sermon-countdown {
        height: $countdown-height;
        position: absolute;
        width: 100%;

        .sermon-countdown-watch-text {
            margin-bottom: 4px;
        }

        .sermon-countdown-number-container {
            font-size: 30px;
            line-height: 26px;
        }

        .sermon-countdown-text {
            font-size: 10px;
            font-weight: normal;
        }
    }

    ::v-deep {
        // Detail text displaying under the stream
        .livestream-details {
            padding: 15px 24px;

            .livestream-details-date {
                color: rgba(0, 0, 0, 0.4);
                text-align: right;
            }

            hr {
                border: none;
                border-top: 2px solid var(--user-alternate-color);
                display: inline-block;
                margin: 0 0 10px 0;
                width: 85px;
            }
        }

        // Giving Form Bottom bar CSS
        .giving-bottom-banner-bg {
            background-color: #0F3E80;
        }

        .giving-bottom-banner {
            background-color: #0F3E80;
            min-width: 100%;
        }

        .expande-collapse-btn {
            background-color: #0F3E80;
            border-radius: 0%;
            border: 0px;
            box-shadow: none;
        }

        .give-now-btn {
            background-color: #FF600A;
        }

        .giving-bottom-bar-text {
            color: #FFFFFF;

            /* Heading 4 */
            font-size: 20px !important;
            font-style: normal !important;
            font-weight: 700 !important;
            line-height: 28px !important; /* 140% */
        }

        .giving-banner-text {
            width: 50%;
            text-align: center;
            float: right;
        }

        #accordion-1 > .card-body > .giving-bottom-bar-text {
            color: #FFFFFF;

            /* Heading 4 */
            font-size: 1.25em !important;
            font-style: normal !important;
            font-weight: 700 !important;
            line-height: 28px !important; /* 140% */
        }
    }
}

// For screens sized "md" and narrower
@include media-breakpoint-down(md) {
    $countdown-height: 110px;

    // Make room for the countdown, which we absolutely position above the stream
    ::v-deep .livestream-container-hasCurrentStream {
        height: auto;
        margin-top: $countdown-height;
    }

    // NB: Override videojs default sizing due to a bug in Chrome
    //     The padding-top method follows video-js' vjs-fluid pattern
    //     I have filed a bug with video-js and we should update this when they respond
    //     https://github.com/videojs/video.js/issues/7018
    ::v-deep {
        .video-js.vjs-fluid {
            height: 54.55vw;
            padding: 0;
        }
    }

    // Move the countdown to the top of the page (under the navigation and above the stream)
    ::v-deep .sermon-countdown {
        height: $countdown-height;
        left: 0;
        position: absolute;
        top: $navbar-height;
        width: 100%;
    }

    // Update the display of details to work on smaller browsers
    .livestream-details {
        h3 {
            font-size: 16px;
        }

        .livestream-details-date {
            font-size: 12px;
        }
    }
}

// For screens sized "sm" and narrower
@include media-breakpoint-down(sm) {
    $countdown-height: 82px;

    // The countdown gets smaller on very small browsers and is still absolutely positioned
    // so make sure the content fits together still
    ::v-deep .livestream-container-hasCurrentStream {
        margin-top: $countdown-height;
    }

    ::v-deep .sermon-countdown {
        height: $countdown-height;
    }
}
</style>
